import { Component, OnInit } from '@angular/core';
import {Http} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Common} from '../../../common/common';

@Component({
  selector: 'app-lwfsettingmenu',
  templateUrl: './lwfsettingmenu.component.html',
  styleUrls: ['./lwfsettingmenu.component.css']
})
export class LwfsettingmenuComponent implements OnInit {

  constructor(private http: Http, private title: Title) {
  }
  lwfsettings = [];
  Common = Common;
  searchText;
  p: Number = 1;
  getLwfSettings () {
    this.http.get(Configuration.serviceCoreURL + '/lwfsetting').subscribe(data => {
      this.lwfsettings = data.json();
    });
  }
  ngOnInit() {
    this.title.setTitle('Octanics - LWF Setting');
    this.getLwfSettings();
  }

}
