import { Component, OnInit } from '@angular/core';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Http} from '@angular/http';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-accountsidemenu',
  templateUrl: './accountsidemenu.component.html',
  styleUrls: ['./accountsidemenu.component.scss']
})
export class AccountsidemenuComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute,private location: Location) { }
    reports = [];
    routes: string;
    roleId: string;

    menucloseclick()
    {
        $('.menuclose a').on('click',function(){
            //alert('hii');
            $('#reportsPlageLeftSidebarMobile').removeClass('show');
        });
    }
    ngOnInit() {
        $(document).ready(function () {
            $('#reportsLeftBtnToggle').on('click',function(){
                $('#reportsPlageLeftSidebarMobile').toggleClass('show');
            });

            $('#reportLinkCloseToggle').on('click',function(){
                $('#reportsPlageLeftSidebarMobile').removeClass('show');
            });
        });
        //this.url=event.substr(0, this.router.url.lastIndexOf("/"));

    }

}
