import {DatePipe} from '@angular/common';
import {Http, Response} from '@angular/http';

export class Utility {
  private static datePipe: DatePipe;
    constructor(private http: Http) {}
    public static serviceStorageURL(){
        return localStorage.getItem('serviceStorageURL');
    }

    public static serviceAnalyticsURL(){
      // return "http://localhost:8006";
        return localStorage.getItem('serviceAnalyticsURL');
    }
    public static  serviceExternalAppsURL(){
        // return "http://localhost:4500";
        return localStorage.getItem('serviceExternalAppsURL');
    }

    public static  serviceIntegrationURL(){
      // return "http://localhost:8003";
        return localStorage.getItem('serviceIntegrationURL');
    }

    public static serviceLicenceURL(){
        return localStorage.getItem('serviceLicenceURL');
    }

    public static serviceLoggingURL(){
        return localStorage.getItem('serviceLoggingURL');
    }

    public static serviceAdminCoreURL(){
        return localStorage.getItem('serviceAdminCoreURL');
    }
    //public static serviceAdminCoreURL = localStorage.getItem('serviceAdminCoreURL');

    public static junctionboxURL() {
        return localStorage.getItem('junctionBoxSubDomainPathUrl') != 'null' ? localStorage.getItem('junctionBoxSubDomainPathUrl') : localStorage.getItem('junctionBoxPathUrl');
    }

    public static junctionboxPath() {
        return localStorage.getItem('junctionBoxPathUrl');
    }

    public static junctionBoxSubDomain() {
        return localStorage.getItem('junctionBoxSubDomain');
    }

    public static databoxPath() {
        return   localStorage.getItem('dataBoxPathUrl');
    }
    // public static junctionboxPath = localStorage.getItem('junctionBoxPathUrl');


    public static getLoggedUserId() {
        return   localStorage.getItem('employeeid');
    }
    public static getCompanyName = localStorage.getItem('companyname');

    public static getCompanyShortName() {
        return localStorage.getItem('companyshortname');
    }
    public static getCompanyMobileNumber = localStorage.getItem('companymobileno');
    // public static getLogged=  sessionStorage.getItem('eid');

    public static editorLicenseKey = localStorage.getItem('froalaEditorKey');
    // public static editorLicenseKey = 'KB8B2C1E2C1A1lG4J4A16C10D3E6D5C2H4F3gkjiE4obvbjletD6f1e=='; // activation key for octanics.io
    //public static editorLicenseKey = 'bA3C8C7D7E5A2A-9H3E2J2C5C6B3C2B5B1B1pbmD-17qE-13B-11pgD1ux=='; // activation key for octanics.co

    public static indiamartKey = localStorage.getItem('indiamartKey');
    public static indiamartNumber = localStorage.getItem('indiamartNumber');
    public static tradeindiaKey = localStorage.getItem('tradeindiaKey');
    public static tradeindiaUserId = localStorage.getItem('tradeindiaUserId');
    public static tradeindiaProfileId = localStorage.getItem('tradeindiaProfileId');
    public static facebookToken = localStorage.getItem('facebookToken');
    public static facebookAdId = localStorage.getItem('facebookAdId');

    public static calculateYearDifference(startdate, enddate) {
        return Math.abs(startdate.diff(enddate, 'years'));
    }

    public static calculateMonthDifference(startdate, enddate) {
        return Math.abs(startdate.diff(enddate, 'months'));
    }

    public static calculateDayDifference(startdate, enddate) {
        return Math.abs(startdate.diff(enddate, 'days'));
    }

    public static getDateTime() {
        var datePipe = new DatePipe("en-IN");
        return datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    }

    public static isOfImageType(extension) {
        let validImageExtensions = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'];

        return validImageExtensions.includes(extension);
    }

    public static isOfImportType(extension) {
        let validImportExtensions = ['csv', 'CSV', 'xls', 'XLS', 'xlsx', 'XLSX'];

        return validImportExtensions.includes(extension);
    }

    public static isOfPDFType(extension) {
        let validPDFExtensions = ['pdf'];

        return validPDFExtensions.includes(extension);
    }

    public static exportFunction(column) {
        var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            /*showTitle: true,*/
            useBom: true,
            headers: column,
            /*nullToEmptyString: Boolean,*/
        };
        return options;
    }

    public static isNull(data) {
        return data == null || data == '';
    }

    public static isUndefined(data) {
        return data == undefined;
    }

    public static isLeapYear(year) {
        return (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0));
    }

    public static numberOnly(event: any) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode !== 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    public static decimalOnly(event: any) {
        //console.log(event); // for testing
        const pattern = /[0-9\+\.\ ]/; // kritika
        // const pattern = /^[0-9]+\.?/; // ashish
        // const pattern = /^[0-9]+(\.[0-9]+)?/;
        // const pattern = /^[-+]?[0-9]+\.[0-9]+$/;
        // const pattern = /^((?:\d|\d{1,3}(?:,\d{3})){0,6})(?:\.\d{1,2}?)?$/;
        const inputChar = String.fromCharCode(event.charCode);
        // (event.keyCode !== 8) &&
        // if (((event.keyCode !== 65 || event.keyCode !== 86 || event.keyCode !== 67) && (event.ctrlKey !== true || event.metaKey !== true)) && (!pattern.test(inputChar))) {

        if ( (event.keyCode !== 8) && (!pattern.test(inputChar))) {
            event.preventDefault();
        }
    }

    public static getShortText(length: number, text) {
        if ( text === '' || text == null ) {
            return text;
        }
        return text.substring(0, length);
    }

    public static getCurrentDateTime() {
        var datePipe = new DatePipe("en-IN");
        return datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm');
    }

    public static replaceStr(str, find, replace) {
        for (var i = 0; i < find.length; i++) {
            str = str.replace(new RegExp(find[i], 'gi'), replace[i]);
        }
        return str;
    }
  public static convertDate(date){
    if(date !== null && date !== undefined && date !== ''){
      var localDate = new Date(date + 'UTC');
      localDate.toString();
      return localDate;
    }else{
      return null;
    }

  }
  public static convertDateUTC(date){
    if(date !== null && date !== undefined && date !== ''){
      let dates = new Date(date).toISOString().slice(0, 19).replace('T', ' ');
      return dates;
    }else{
      return null;
    }

  }


    public static GenerateLogMessage(parameter) {
        var findEmail = parameter.hasOwnProperty('email');
        var findCc = parameter.hasOwnProperty('cc');
        var findBcc = parameter.hasOwnProperty('bcc');
        if((findEmail) && (findCc) && (findBcc)){
            var text = "{{message}} {{customer}} on {{datetime}} at {{email}}, {{cc}}, {{bcc}} via {{communicationtype}}.";
            var find = ['{{message}}', '{{customer}}', '{{datetime}}','{{email}}','{{cc}}','{{bcc}}', '{{communicationtype}}'];
            var replace = [parameter.message, parameter.customer, parameter.datetime, parameter.email, parameter.cc, parameter.bcc, parameter.communicationtype];
            text = Utility.replaceStr(text, find, replace);
        }else if((findEmail) && (findCc)){
            var text = "{{message}} {{customer}} on {{datetime}} at {{email}}, {{cc}} via {{communicationtype}}.";
            var find = ['{{message}}', '{{customer}}', '{{datetime}}','{{email}}','{{cc}}', '{{communicationtype}}'];
            var replace = [parameter.message, parameter.customer, parameter.datetime, parameter.email, parameter.cc, parameter.communicationtype];
            text = Utility.replaceStr(text, find, replace);
        }else if((findEmail) && (findBcc)){
            var text = "{{message}} {{customer}} on {{datetime}} at {{email}}, {{bcc}} via {{communicationtype}}.";
            var find = ['{{message}}', '{{customer}}', '{{datetime}}','{{email}}','{{bcc}}', '{{communicationtype}}'];
            var replace = [parameter.message, parameter.customer, parameter.datetime, parameter.email, parameter.bcc, parameter.communicationtype];
            text = Utility.replaceStr(text, find, replace);
        }
        else if(findEmail){
            var text = "{{message}} {{customer}} on {{datetime}} at {{email}} via {{communicationtype}}.";
            var find = ['{{message}}', '{{customer}}', '{{datetime}}','{{email}}', '{{communicationtype}}'];
            var replace = [parameter.message, parameter.customer, parameter.datetime, parameter.email, parameter.communicationtype];
            text = Utility.replaceStr(text, find, replace);
        }
        else{
            var text = "{{message}} {{customer}} on {{datetime}} via {{communicationtype}}.";
            var find = ['{{message}}', '{{customer}}', '{{datetime}}', '{{communicationtype}}'];
            var replace = [parameter.message, parameter.customer, parameter.datetime, parameter.communicationtype];
            text = Utility.replaceStr(text, find, replace);
        }
        return text;
    }

    /*public static shortenUrl($url) {
     const url = 'https://api-ssl.bitly.com/v4/shorten';

     const headers = new HttpHeaders()
     .set('cache-control', 'no-cache')
     .set('content-type', 'application/json')
     .set('Authorization', 'aba7df0a852989a7acddd07bf12c23055334429e');

     const body = {
     long_url: $url,
     domain: 'bit.ly'
     };

     return this.http.post(url, body, { headers: headers }).subscribe((res: Response) => {
     res['link'];
     });

     fetch('https://api-ssl.bitly.com/v4/shorten', {
     method: 'POST',
     headers: {
     'Authorization': 'aba7df0a852989a7acddd07bf12c23055334429e',
     'Content-Type': 'application/json'
     },
     body:
     JSON.stringify({
     'long_url': $url,
     'domain': 'bit.ly'
     })
     });
     }*/
  public static getabstractKey() {
    return localStorage.getItem('abstractKey');
  }
}
