import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {FormGroup, Validators, FormControl, AbstractControl} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import * as bcrypt from 'bcryptjs';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {Localstorage} from '../common/localstorage';
import {TokenService} from '../common/token.service';
import {HttpClient} from '@angular/common/http';
import {Lookup} from '../common/lookup';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    constructor(private http: HttpClient, private router: Router,
                private route: ActivatedRoute, private title: Title, private Localsecurestorage: Localstorage,
                private Token: TokenService, private lookUp: Lookup) {
    }

    clientIp: any;
    code;
    ipAddress: any;
    LoginForm: FormGroup;
    userDetail: object = {};
    errorMessage = '';
    loginMessage = '';
    id: string;
    userid: string;
    status: boolean;
    licenseDetails = [];
    employerDetails: object = [];
    systemapi: any;
    addresstypes = [];
    registeredId: any;
    pageRow;
    advancePageRow;
    business_id;

    getSystemApi() {
        this.http.get(Configuration.serviceCoreURL + '/getsystemapis').subscribe(systemapidata => {
            this.systemapi = systemapidata;

            let core = this.systemapi.find(x => x.name == 'core');
            if (!Utility.isUndefined(core))
                localStorage.setItem('serviceCoreURL', core['url']);

            let storage = this.systemapi.find(x => x.name == 'storage');
            if (!Utility.isUndefined(storage))
                localStorage.setItem('serviceStorageURL', storage['url']);

            let analytics = this.systemapi.find(x => x.name == 'analytics');
            if (!Utility.isUndefined(analytics))
                localStorage.setItem('serviceAnalyticsURL', analytics['url']);

            let externalapps = this.systemapi.find(x => x.name == 'externalapps');
            if (!Utility.isUndefined(externalapps))
                localStorage.setItem('serviceExternalAppsURL', externalapps['url']);

            let integration = this.systemapi.find(x => x.name == 'integration');
            if (!Utility.isUndefined(integration))
                localStorage.setItem('serviceIntegrationURL', integration['url']);

            let license = this.systemapi.find(x => x.name == 'license');
            if (!Utility.isUndefined(license))
                localStorage.setItem('serviceLicenceURL', license['url']);

            let logging = this.systemapi.find(x => x.name == 'logging');
            if (!Utility.isUndefined(logging))
                localStorage.setItem('serviceLoggingURL', logging['url']);

            let junctionboxpath = this.systemapi.find(x => x.name == 'junctionboxpath');
            if (!Utility.isUndefined(junctionboxpath))
                localStorage.setItem('junctionBoxPathUrl', junctionboxpath['url']);

            let junctionboxsubdomain = this.systemapi.find(x => x.name == 'junctionboxsubdomain');
            if (!Utility.isUndefined(junctionboxsubdomain))
                localStorage.setItem('junctionBoxSubDomain', junctionboxsubdomain['url']);

            let froalaeditorkey = this.systemapi.find(x => x.name == 'froalaeditorkey');
            if (!Utility.isUndefined(froalaeditorkey))
                localStorage.setItem('froalaEditorKey', froalaeditorkey['url']);

            let databoxpath = this.systemapi.find(x => x.name == 'databoxpath');
            if (!Utility.isUndefined(databoxpath))
                localStorage.setItem('dataBoxPathUrl', databoxpath['url']);

            let admin = this.systemapi.find(x => x.name == 'admin');
            if (!Utility.isUndefined(admin))
                localStorage.setItem('serviceAdminCoreURL', admin['url']);

            let indiamartkey = this.systemapi.find(x => x.name == 'indiamartkey');
            if (!Utility.isUndefined(indiamartkey))
                localStorage.setItem('indiamartKey', indiamartkey['url']);

            let indiamartNumber = this.systemapi.find(x => x.name == 'indiamartnumber');
            if (!Utility.isUndefined(indiamartNumber))
                localStorage.setItem('indiamartNumber', indiamartNumber['url']);

            let tradeindiaKey = this.systemapi.find(x => x.name == 'tradeindiakey');
            if (!Utility.isUndefined(tradeindiaKey))
                localStorage.setItem('tradeindiaKey', tradeindiaKey['url']);

            let tradeindiaUserId = this.systemapi.find(x => x.name == 'tradeindiauserid');
            if (!Utility.isUndefined(tradeindiaUserId))
                localStorage.setItem('tradeindiaUserId', tradeindiaUserId['url']);

            let tradeindiaProfileId = this.systemapi.find(x => x.name == 'tradeindiaprofileid');
            if (!Utility.isUndefined(tradeindiaProfileId))
                localStorage.setItem('tradeindiaProfileId', tradeindiaProfileId['url']);

            let payuKey = this.systemapi.find(x => x.name == 'payukey');
            if (!Utility.isUndefined(payuKey))
                localStorage.setItem('payuKey', payuKey['url']);

            let payuSalt = this.systemapi.find(x => x.name == 'payusalt');
            if (!Utility.isUndefined(payuSalt))
                localStorage.setItem('payuSalt', payuSalt['url']);

            let abstractkey = this.systemapi.find(x => x.name == 'abstractkey');
            if (!Utility.isUndefined(abstractkey))
              localStorage.setItem('abstractKey', abstractkey['url']);

            let mailUsername = this.systemapi.find(x => x.name == 'MAIL_USERNAME');
            if (!Utility.isUndefined(mailUsername))
                localStorage.setItem('MAIL_USERNAME', mailUsername['url']);

            let mailPassword = this.systemapi.find(x => x.name == 'MAIL_PASSWORD');
            if (!Utility.isUndefined(mailPassword))
                localStorage.setItem('MAIL_PASSWORD', mailPassword['url']);

            let facebookToken = this.systemapi.find(x => x.name == 'facebooktoken');
            if (!Utility.isUndefined(facebookToken))
              localStorage.setItem('facebookToken', facebookToken['url']);

            let facebookAdId = this.systemapi.find(x => x.name == 'facebookadid');
            if (!Utility.isUndefined(facebookAdId))
              localStorage.setItem('facebookAdId', facebookAdId['url']);
        });
    }

    validatelogin(login) {
        if (!this.LoginForm.valid) {
        } else {

            this.http.get(Configuration.serviceCoreURL + '/getuser?email=' + login.username).subscribe(data => {
                if (data === '' || data == null ) {
                    this.errorMessage = 'Invalid Username and Password !';
                }
                else {
                    this.getSystemApi();
                    this.getPageRow();
                    this.getAdvancePageRow();
                    this.userDetail = data;
                    // console.log(this.userDetail);
                    this.status = bcrypt.compareSync(login.password, this.userDetail['password']);
                    if (this.status === true) {
                        if (this.userDetail['isdisabled'] == true) {
                            this.errorMessage = 'Your account has been disabled, please contact administrator.';
                        } else {
                            this.http.post(Configuration.serviceAuthorisationURL + '/login', {
                                email: login.username,
                                url: Configuration.serviceCoreURL
                            }).subscribe(res => {
                                let token = res;
                                // this._messageService.setUser(token);
                                localStorage.setItem('userid', this.userDetail['id']);
                                // this.Token.setValueinLocalstorage(token);
                                localStorage.setItem('token', token['token']);
                                this.http.post(Configuration.serviceAuthorisationURL + '/updatetoken', {
                                    id: localStorage.getItem('userid'),
                                    token: token['token'],
                                    url: Configuration.serviceCoreURL
                                }).subscribe((data) => {
                                    this.Localsecurestorage.setStorage('useuserid', this.userDetail['id']);
                                    this.Localsecurestorage.setStorage('useroleid', this.userDetail['roleid']);
                                    this.Localsecurestorage.setStorage('useemail', this.userDetail['email']);
                                    this.Localsecurestorage.setStorage('useusername', this.userDetail['username']);
                                    this.Localsecurestorage.setStorage('useemployeeid', this.userDetail['employee_id']);
                                    this.Localsecurestorage.setStorage('uselandingroute', this.userDetail['landingroute']);
                                    // this.Localsecurestorage.getStorage('useemail');
                                    localStorage.setItem('email', this.userDetail['email']);
                                    localStorage.setItem('roleid', this.userDetail['roleid']);
                                    localStorage.setItem('username', this.userDetail['username']);
                                    localStorage.setItem('firstname', this.userDetail['firstname']);
                                    localStorage.setItem('lastname', this.userDetail['lastname']);
                                    localStorage.setItem('rolename', this.userDetail['rolename']);
                                    localStorage.setItem('employeeid', this.userDetail['employee_id']);
                                    localStorage.setItem('profilephoto', this.userDetail['profilephoto']);
                                    localStorage.setItem('aliasrolename', this.userDetail['aliasrolename']);
                                    localStorage.setItem('landingroute', this.userDetail['landingroute']);
                                    localStorage.setItem('password', login.password);
                                    localStorage.setItem('activetab', 'lead');
                                    if (this.userDetail['landingroute'] !== null) {
                                        this.router.navigateByUrl(this.userDetail['landingroute']);
                                    } else {
                                        this.router.navigate(['/**']);
                                    }
                                });
                                //  localStorage.setItem('token', token['token']);
                            });
                            // this.localService.setJsonValue('user', login.password);
                            // this._messageService.setUser(this.userDetail);
                            this.userid = this.userDetail['id'];
                            this.http.get(Configuration.serviceCoreURL + '/getbusinessid?user_id=' + this.userid).subscribe((businessid: any) => {
                                if (!Utility.isNull(businessid)) {
                                    localStorage.setItem('businessid', businessid);
                                    this.business_id = businessid;
                                    this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(empdata => {
                                        this.employerDetails = empdata;
                                        localStorage.setItem('companyname', this.employerDetails['nameoforganization']);
                                        localStorage.setItem('companymobileno', this.employerDetails['mobilenoofowner']);
                                        localStorage.setItem('companyshortname', this.employerDetails['companyshortname']);
                                    });
                                    // this.business_id = businessid.json();
                                }
                            });
                            let email = {
                                'username':localStorage.getItem('MAIL_USERNAME'),
                                'password':localStorage.getItem('MAIL_PASSWORD'),
                            };
                            this.http.post(Utility.serviceIntegrationURL() + '/setemailconfiguration',email ).subscribe((data) => {
                            });
                            this.http.get(Configuration.serviceCoreURL + '/getdocumentsizelimit').subscribe((docsizelimit: any) => {
                                if (!Utility.isNull(docsizelimit)) {
                                    localStorage.setItem('docsizelimit', docsizelimit);
                                }
                            });
                            this.UpdateLoggedUserDetail(this.userDetail['id']);
                        }
                    }
                    else {
                        this.errorMessage = 'Invalid Username and Password !';
                    }
                }
            });
        }
    }

    UpdateLoggedUserDetail(id) {
        this.http.get<{ ip: string }>('https://jsonip.com').subscribe(data => {
            this.ipAddress = data;
            this.clientIp = this.ipAddress['ip'];
            this.http.get(Configuration.serviceCoreURL + '/updateloggeduserdetail?id=' + id + '&ip=' + this.clientIp).subscribe(res => {
            });
        });

    }

    getPageRow() {
        this.http.get(Configuration.serviceCoreURL + '/getdefaultrowvalue?code=NOOFROW').subscribe(data => {
            if (data !== '' && data !== null && data !== undefined) {
                this.pageRow = data;
            } else {
                this.pageRow = 20;
            }
            localStorage.setItem('pageSize', this.pageRow);
        });
    }

    getAdvancePageRow() {
        this.http.get(Configuration.serviceCoreURL + '/getdefaultrowvalue?code=ADVANCENOOFROWS').subscribe(data => {
            if (data !== '' && data !== null && data !== undefined) {
                this.advancePageRow = data;
            } else {
                this.advancePageRow = 50;
            }
            localStorage.setItem('advancepageSize', this.advancePageRow);
        });
    }

    ngOnInit() {
        this.id = localStorage.getItem('userid');
        this.title.setTitle('Octanics - Login');
        this.Token.checkTokenAtLogin();
        this.getSystemApi();
        this.getPageRow();
        this.getAdvancePageRow();
        this.lookUp.getLookUp('addresstypes').subscribe((data) => {
            if (data) {
                this.addresstypes = data;
                let type = this.addresstypes.find(x => x.code == 'REGISTERED');
                this.registeredId = type['id'];
            }
        });
        this.LoginForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        });
    }

}
