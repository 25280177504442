//File: cancelledserialnumberview.component.ts
//Created By: pradeep valiya on 02.12.2022
//Copyright (C) 2022-Present, Octanics Software LLP. All rights reserved.
import {Component, OnInit} from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Title} from '@angular/platform-browser';
import {Common} from "../../../common/common";
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
  selector: 'app-cancelledserialnumberview',
  templateUrl: './cancelledserialnumberview.component.html',
  styleUrls: ['./cancelledserialnumberview.component.scss']
})
export class CancelledserialnumberviewComponent implements OnInit {

  serialnumberdetails: object = {};
  id: number;
  type: string;
  Common = Common;
  constructor(private http: HttpClient, private router: Router, private title: Title, private route: ActivatedRoute, private fb: FormBuilder) {

  }

  ngOnInit() {
    this.title.setTitle('Octanics - Cancelled Serial Number');

    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });

    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
    });

    this.http.get(Configuration.serviceCoreURL + "/cancelledserialnumberdetails?id=" + this.id).subscribe(data => {
      this.serialnumberdetails = data;
    });
  }

}
