import {Component, OnInit} from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {FormControl, FormGroup, Validators} from '@angular/forms';

declare var $: any;

@Component({
    selector: 'app-scmemailtemplate',
    templateUrl: './scmemailtemplate.component.html',
    styleUrls: ['./scmemailtemplate.component.css']
})
export class ScmemailtemplateComponent implements OnInit {
  constructor(private http: Http, private router: Router, private title: Title, private route: ActivatedRoute) {
  }

  public options: Object = {
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
      'insertTable', 'insertLink'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
      'insertTable', 'insertLink'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
      'insertTable', 'insertLink'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
      'insertTable', 'insertLink'],
  };
  templateEmailDetail: object = [];
  templateSmsDetail: object = [];
  email;
  value: number;
  communicationmodes = [];
  templates = [];
  templateForm: FormGroup;
  getCommunicationMode = function () {
    this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=communicationmodes').subscribe(data => {
      this.communicationmodes = data.json();
    });
  }
  getTemplate = function () {
    this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=scmtemplates').subscribe(data => {
      this.templates = data.json();
    });
  };

  selectEmailTemplateDetail(args) {
    this.value = args.target.value;
    this.http.get(Configuration.serviceCoreURL + '/templatedetail?id=' + this.value).subscribe(data => {
      this.templateEmailDetail = data.json();
    });
  }
  selectSmsTemplateDetail(args) {
    this.value = args.target.value;
    this.http.get(Configuration.serviceCoreURL + '/templatedetail?id=' + this.value).subscribe(data => {
      this.templateSmsDetail = data.json();
    });
  }
  onChange($event) {
    this.email = $event;
  }

  insertSmsTemplate = function (sms) {
    sms.created_by = Utility.getLoggedUserId();
    sms.modified_by = Utility.getLoggedUserId();
    sms.subject = null;
    if (this.templateSmsDetail['id'] === undefined) {

      this.http.post(Configuration.serviceCoreURL + '/inserttemplate', sms).subscribe((res: Response) => {
        $('#frmsms').get(0).reset();
        this.router.navigate(['scmtemplates']);
      });
    } else {
      sms.id = this.templateSmsDetail['id'];
      this.http.post(Configuration.serviceCoreURL + '/updatetemplate', sms).subscribe((res: Response) => {
        $('#frmsms').get(0).reset();
        this.router.navigate(['scmtemplates']);
      });
    }
  };
  insertEmailTemplate = function (email) {
    email.name = this.email;
    email.created_by = Utility.getLoggedUserId();
    email.modified_by = Utility.getLoggedUserId();
    if (this.templateEmailDetail['id'] === undefined) {
      this.http.post(Configuration.serviceCoreURL + '/inserttemplate', email).subscribe((res: Response) => {
        $('#frmemail').get(0).reset();
        this.router.navigate(['scmtemplates']);
      });
    } else {
      email.id = this.templateEmailDetail['id'];
      this.http.post(Configuration.serviceCoreURL + '/updatetemplate', email).subscribe((res: Response) => {
        $('#frmemail').get(0).reset();
        this.router.navigate(['scmtemplates']);
      });
    }
  };

  ngOnInit() {
    $('.navbar-collapse li.active').removeClass('active');
    $('#menumaster').addClass('active');
    this.title.setTitle('Octanics - SCM  Templates');
    this.getTemplate();
    this.getCommunicationMode();
  }
}
