import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from '@app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Lookup} from '@app/common/lookup';
import {Common} from '@app/common/common';
import {HttpClient} from "@angular/common/http";
@Component({
  selector: 'app-updateschedulerjob',
  templateUrl: './updateschedulerjob.component.html',
  styleUrls: ['./updateschedulerjob.component.css']
})
export class UpdateschedulerjobComponent implements OnInit {

  constructor(private http: HttpClient, private httpclient: HttpClient,  private router: Router, private route: ActivatedRoute, private title: Title, private lookUp: Lookup) {
  }

  configurationForm: FormGroup;
  id: number;
  configurationdetail: object = {};
  communicationModes = [];
  templateTypes = [];
  getLookUp = function(){
    this.lookUp.getLookUp('communicationmodes').subscribe((data) => {if (data) {this.communicationModes = data; }});
    this.lookUp.getLookUp('templatetypes').subscribe((data) => {if (data) {this.templateTypes = data; }});
  }
  deleteConfiguration = function (id) {
    if (confirm("Are you Sure?")) {

      this.configurationdetail = {
        "id": id,
        "deleted_by":  Utility.getLoggedUserId(),
      };
      this.http.post(Configuration.serviceCoreURL + '/deleteschedulerjobconfiguration', this.configurationdetail).subscribe((res: Response) => {
        this.router.navigate(['/schedulerjob']);
      });
    }
  };
  updateConfiguration = function (configuration) {
    if (this.configurationForm.valid) {
      configuration.id = this.id;
      configuration.modified_by = Utility.getLoggedUserId();
      this.http.post(Configuration.serviceCoreURL + '/updateschedulerjobconfiguration', configuration).subscribe((res: Response) => {
        this.router.navigate(['/schedulerjob']);
      });
    }
  };

  ngOnInit() {
    $('.navbar-collapse li.active').removeClass('active');
    $('#menubackoffice').addClass('active');
    this.title.setTitle('Octanics - Scheduler Job ');
    this.getLookUp();
    this.route.paramMap.subscribe(params => {
      this.id =  Common.decryptId((params.get('id')));
    });
    this.configurationForm = new FormGroup({
      communicationmode_id: new FormControl('', Validators.required),
      template_id: new FormControl('', Validators.required),
      pattern: new FormControl('', Validators.required),
    });
    this.http.get(Configuration.serviceCoreURL + '/schedulerjobconfigurationdetails?id=' + this.id).subscribe(data => {
      this.configurationdetail = data;
    });


  }


}

