import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Performancekra} from "app/models/performancekra.model";
import {Kradetails} from "app/models/kradetails.model";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-insertperformancekra',
    templateUrl: './insertperformancekra.component.html',
    styleUrls: ['./insertperformancekra.component.css']
})
export class InsertperformancekraComponent implements OnInit {

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    }

    performancekra: Performancekra;
    kraDetails = [];
    selectedkra_id: number;
    kraHistory: Array<Kradetails> = [];
    history: object = {};
    selectedtarget: string;
    selectedactual: string;
    selectedpending: string;
    selectedcomments: string;
    selectedHistory: Kradetails;

    getKraDetails = function () {
        this.http.get(Configuration.serviceCoreURL + "/getlookup?lookuptype=kras").subscribe(data => {
            this.kraDetails = data;
            // console.log(this.data);
        });
    };
    insertPerformancekra = function (performancekra) {
        performancekra.created_by = -1;
        performancekra.employee_id = null;
        performancekra.modified_by = -1;
        // console.log(performancekra);
        this.http.post(Configuration.serviceCoreURL + "/insertperformancekra", performancekra).subscribe((res: Response) => {
            this.id = res['id'];
            if (this.id) {
                this.kraHistory.forEach(obj => {
                    obj.performanckra_id = this.id;
                    obj.created_by = -1;
                    // obj.created_at =  Utility.getDateTime();
                    obj.modified_by = -1;
                    // obj.modified_at =  Utility.getDateTime();
                    // console.log(obj);
                    this.http.post(Configuration.serviceCoreURL + "/insertkrahistory", obj).subscribe((res: Response) => {
                    });
                });
            }
            this.router.navigate(['/performancekpi']);
        });
    };
    insertKraHistory = function (history) {

        if (this.selectedHistory !== undefined) {
            this.selectedHistory.target = history.target;
            this.selectedHistory.actual = history.actual;
            this.selectedHistory.comments = history.comments;
            this.selectedHistory.pending = history.pending;
            this.selectedHistory.kra_id = history.kra_id;
        } else {
            let customObj = new Kradetails();
            customObj.target = history.target;
            customObj.actual = history.actual;
            customObj.pending = history.pending;
            customObj.comments = history.comments;
            customObj.kra_id = history.kra_id;
            this.kraHistory.push(customObj);
        }
        // console.log(this.kraHistory);
    };
    deletekraHistory = function (id) {
        if (confirm("Are you Sure?")) {
            this.kraHistory.splice(id, 1);
        }
    };

    editkraHistory = function (editdata) {
        // console.log('edit call');
        this.selectedname = editdata.name;
        this.selectedtarget = editdata.comment;
        this.selectedactual = editdata.date;
        this.selectedpending = editdata.date;
        this.selectedcomments = editdata.date;
        this.selectedHistory = editdata;
        // console.log(this.selectedHistory);
    };


    ngOnInit() {
        this.getKraDetails();
    }


}
