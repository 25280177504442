/* File: footer.component.ts
Created By: Kajal on 07.11.2022
Copyright (C) 2022-Present, Octanics Software LLP. All rights reserved.
*/
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

  constructor() {
  }
  landingroute: string;

  CurrentYear () {
    const currentdate = new Date();
    return currentdate.getFullYear();
  }

  ngOnInit() {
    this.CurrentYear();
    this.landingroute = localStorage.getItem('landingroute');
  }

}
