import {Component, OnInit} from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Lookup} from '../../../../common/lookup';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Common} from '../../../../common/common';


@Component({
    selector: 'app-travelallowanceview',
    templateUrl: './travelallowanceview.component.html',
    styleUrls: ['./travelallowanceview.component.css']
})
export class TravelallowanceviewComponent implements OnInit {

    id: number;
    travelallowancesdetails: object = {};

    constructor(private http: Http, private router: Router, private route: ActivatedRoute,
                private fb: FormBuilder, private lookUp: Lookup) {
    }

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menupayroll').addClass('active');

        this.route.paramMap.subscribe(params => {
            this.id = Common.decryptId((params.get('id')));
        });
        this.http.get(Configuration.serviceCoreURL + "/travelallowancesdetails?id=" + this.id).subscribe(data => {
            this.travelallowancesdetails = data.json();
        });
    }

}
